import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useRef } from "react"
import Seo from "../components/Layout/Seo"
import Site from "../components/Layout/Site"
import { Container, Row, Col } from "../components/UI/Grid"
import ScaledElement from "../components/UI/ScaledElement"
import ScaledImage from "../components/UI/ScaledImage"
import { Section } from "../components/UI/Section"
import { Heading, Paragraph } from "../components/UI/Typography"
import HeavyLifting from "../components/Shared/HeavyLifting"
import image1 from "../images/spare-parts.jpg"
import image2 from "../images/trained-technicians.jpg"
import image3 from "../images/loading-dock.jpg"
import image4 from "../images/skilled-installers.jpg"
import image5 from "../images/after-sales-service.jpg"
import image6 from "../images/rental-trucks-service.jpg"
import styled, { css } from "styled-components"
import rfs from "../utils/rfs"
import Button from "../components/UI/Button"
import Element from "../components/UI/Element"
import ArrowRightIcon from "../components/Icons/ArrowRightIcon"
import CheckIcon from "../components/Icons/CheckIcon"
import { Link } from "gatsby"

const Services = ({ location }) => {
  const sparePartsRef = useRef(null)
  const trainedTechRef = useRef(null)
  const dockSystemRef = useRef(null)
  const skilledInstallersRef = useRef(null)
  const afterSalesRef = useRef(null)
  const rentalRef = useRef(null)

  useEffect(() => {
    if (location.state && location.state.section) {
      const scrollToRef = (ref) => {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }

      if (location.state.section === "spare-parts") scrollToRef(sparePartsRef)
      else if (location.state.section === "trained-technicians")
        scrollToRef(trainedTechRef)
      else if (location.state.section === "dock-system")
        scrollToRef(dockSystemRef)
      else if (location.state.section === "skilled-installers")
        scrollToRef(skilledInstallersRef)
      else if (location.state.section === "after-sales")
        scrollToRef(afterSalesRef)
      else if (location.state.section === "rental-trucks")
        scrollToRef(rentalRef)
    }
  }, [location?.state])

  const sections = [
    {
      image: image1,
      title: "Spare Parts",
      desc: `We have a wide range of spare parts available in our warehouses locally. These are genuine spare parts specifically designed and made for our trucks. Jungheinrich Singapore has a Regional Spare Parts Center that has up to 95% spare parts availability.`,
      desc2: "",
      checklist: [],
      ref: sparePartsRef,
    },
    {
      image: image2,
      title: "Preventive Maintenance Schedule",
      desc: `We offer free PMS for trucks purchased and rented from us. We want to provide you with the best tools to grow your business and operate smoothly.`,
      desc2: "",
      ref: trainedTechRef,
    },
    {
      image: image3,
      title: "Operator Training",
      desc: `Operator Training is an obligation for us. We want our clients to know how to operate safely and properly maintain our trucks. This helps minimize any chances of accidents or damages during the machines' operations.`,
      desc2: "",
      checklist: [],
      ref: dockSystemRef,
    },
    {
      image: image4,
      title: "Repairs",
      desc: `While we ensure to give you the highest quality products, wear and tear are inevitable for most things in the industry. But we provide swift repairs that last.`,
      desc2: "",
      checklist: [],
      ref: skilledInstallersRef,
    },
    {
      image: image5,
      title: "After-Sales",
      desc: `Boeing Material Handling Corporation takes pride in having the best technicians and engineers. We provide top-notch service in maintaining and repairing your trucks to maintain the highest efficiency`,
      desc2: "",
      checklist: [],
      ref: afterSalesRef,
    },
    {
      image: image6,
      title: "Rental and Used Trucks",
      desc: `We have stocks available for brand-new and used trucks in our local warehouses and over 200 trucks available for rent in Jungheinrich Singapore. We offer long-term and short-term rentals, designed to fit your exact needs.`,
      desc2: "",
      checklist: [],
      ref: rentalRef,
    },
  ]

  return (
    <Site>
      <Seo title="Services" />
      <ScaledElement scaleWidth={1440} scaleHeight={240} fullWidth>
        <ScaledImage bg={true}>
          <StaticImage
            src="../images/banner-services.jpg"
            alt="banner"
            layout="fullWidth"
            loading="eager"
          />
        </ScaledImage>
      </ScaledElement>
      <Section>
        <Container>
          <Row justifyContent="center" textAlign="center">
            <Col col={{ lg: 6 }}>
              <Heading as="h1" fs="h1" mb={4}>
                Services
              </Heading>
              <Paragraph>
                Boeing Material Handling Corporation provides the best services
                in the market. We ensure your trucks are well-maintained to keep
                maximum uptime of operations. We make tailored plans for your
                specific warehouse needs.
              </Paragraph>
            </Col>
          </Row>
        </Container>
      </Section>
      {sections.map((section, index) => {
        const isOdd = index % 2 === 1
        return (
          <StyledSection odd={isOdd} key={index} ref={section.ref}>
            <Container>
              <Row justifyContent="space-between">
                <Col
                  col={{ md: 6 }}
                  order={{ lg: isOdd && "1" }}
                  mb={{ _: 6, md: 0 }}
                >
                  <Shadow>
                    <ScaledImage scaleWidth={570} scaleHeight={500}>
                      <img src={section.image} alt={section.title} />
                    </ScaledImage>
                  </Shadow>
                </Col>
                <Col col={{ md: 6, lg: 5 }}>
                  <Paragraph fs="h3" fontWeight="bold" lineHeigt={1.2}>
                    {section.title}
                  </Paragraph>
                  {section.desc && <Paragraph mt={4}>{section.desc}</Paragraph>}
                  {section.desc2 && (
                    <Paragraph mt={4}>{section.desc2}</Paragraph>
                  )}
                  {section.checklist && (
                    <Element mt={4}>
                      {section.checklist.map((c, idx) => (
                        <Row key={idx} mt={idx !== 0 && 2}>
                          <Col col="auto" color={!isOdd && "var(--brand)"}>
                            <CheckIcon />
                          </Col>
                          <Col col="fill">{c}</Col>
                        </Row>
                      ))}
                    </Element>
                  )}
                  <Row>
                    <Col col={{ _: 7 }}>
                      <Button
                        variant={isOdd ? "white" : "brand"}
                        size="lg"
                        block="true"
                        as={Link}
                        to="/contact"
                        mt={8}
                      >
                        <Element
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                          alignItems="center"
                        >
                          <span>Get a quote</span>
                          <ArrowRightIcon />
                        </Element>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </StyledSection>
        )
      })}
      <HeavyLifting />
    </Site>
  )
}
const Shadow = styled.div`
  box-shadow: 6px 6px 0px rgba(84, 85, 181, 0.6);
`

const StyledSection = styled.section`
  position: relative;
  background-color: ${(p) => (p.odd ? "var(--brand)" : "#fff")};
  color: ${(p) => p.odd && "#fff"};
  ${rfs("64px", "padding-top")};
  ${rfs("64px", "padding-bottom")};

  ${(p) =>
    p.odd &&
    css`
      ${Shadow} {
        box-shadow: 6px 6px 0px rgba(255, 255, 255, 0.6);
      }
    `};
`
export default Services
